import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  public _offersUrl = this.common._hostName + "offers.php?action=";
  public offersImagesUrl = this.common._ImageUrl + "offers/";

  constructor(private http: HttpClient, public common: CommonService) { }

  getOneOffer(offerId: number) {
    return this.http.get<any>(
      this._offersUrl + "getOneOffer" + "&id=" + offerId
    );
  }
}

