<div class="row" style="row-gap: 15px;">
    <div class="col-12">
        <h4 class="text-center success-order-header">{{'congratulations'| translate}}</h4>
        <h4 class="text-center success-order-header" *ngIf="productItems[0].message=='edit' else add">
            {{'success-order-edit'| translate }}</h4>
        <ng-template #add>
            <h4 class="text-center success-order-header">{{'success-order'| translate }}</h4>
        </ng-template>

        <h4 class="text-center success-order-header">{{'thank_you_for_shopping' | translate: {
            zahraegypt:contanctInfo?.front_name} }}</h4>
    </div>
    <ng-container *ngIf="productItems.length >1 else single">
        <div class="tabs">
            <div *ngFor="let product of productItems; let i = index" (click)="selectTab(i)"
                [ngClass]="{'active': selectedTab === i}">
                {{ 'Order' | translate }} {{ i + 1 }}
            </div>
        </div>

        <div class="tab-content">
            <app-success [products]="productItems[selectedTab]"  class="w-100"></app-success>
        </div>
    </ng-container>
    <ng-template #single>
        <ng-container *ngFor="let product of productItems;">
            <app-success [products]="product" [singleorder]="true" class="w-100"></app-success>
        </ng-container>
    </ng-template>



</div>