import {
  Component,
  OnInit,
  Input,
  ViewChild,
  TemplateRef,
  ViewChildren,
  ElementRef,
  QueryList,
} from "@angular/core";
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { CommonService } from "../../../services/common.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

import Swal from "sweetalert2";
import { MatBottomSheet } from "@angular/material/bottom-sheet";

declare let $: any;

@Component({
  selector: "app-product-box-one",
  templateUrl: "./product-box-one.component.html",
  styleUrls: ["./product-box-one.component.scss"],
})
export class ProductBoxOneComponent implements OnInit {
  note_id: number;
  noteLoader: boolean = true;
  closeResult = "";
  statbrand: string;
  isFavourit: boolean = false;
  @Input() product: any;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() thumbnail: boolean = false; // Default False
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;
  @ViewChildren("label") label?: QueryList<ElementRef>;
  @ViewChild("templateModalShipping", { static: false })
  templateModalShipping: TemplateRef<any>;
  showPrice: boolean = false;
  public ImageSrc: string;
  currentRoute: string = "";
  notifyMeText = "";
  productIdToListenToAvailability: any;
  @ViewChild("templateBottomSheet") TemplateBottomSheet: TemplateRef<any>;
  urlimage;
  last_price;
  constructor(
    private router: Router,
    private productService: ProductService,
    public Common: CommonService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private bottomSheet: MatBottomSheet
  ) {
    this.urlimage = this.Common._ImageUrl + "products/thumbs/";
    // GET CURRENT ROUTE
    this.currentRoute = this.router.url.split("/")[2];
    //console.log("Current Route :", this.currentRoute);
  }
  checkshowPrice() {
    if (this.Common.getShowPrice() == "1") {
      this.showPrice = true;
    }
  }
  removeBrackets(str: string): string {
    if (str != null) {
      return str?.replace(/[\()/%]/g, "");
    }
  }

  // TRUNCATE TEXT
  transform(text: string, length: number = 8, suffix: string = ".."): string {
    if (text.length > length) {
      let truncated: string = text.substring(0, length).trim() + suffix;
      return truncated;
    }
    return text;
  }

  ngOnInit(): void {
    this.Common.UpdatedCartProducts$.subscribe((id) => {
      if (this.product["id"] == id) {
        this.product.quantity_in_cart = "0";
        this.Common.UpdatedCartProducts$.next("");
      }
    });
    // PRODUCT DETAILS TRUNCATE CONDITIONS
    // this.statbrand = "hello";
    // let len = this.statbrand.length
    // console.log("THIS IS THE PRODUCT BRAND", this.statbrand);
    this.last_price = parseInt(this.product.last_price);
    this.checkshowPrice();
    //console.log(this.product?.meta_title !== undefined);
    //console.log(this.product);

    // console.log("this.product",this.product);
    if (this.loader) {
      setTimeout(() => {
        this.loader = false;
      }, 2000); // Skeleton Loader
    }
  }

  ngAfterViewInit(): void {
    this.label?.forEach((ele: ElementRef) => {
      this.setFontColorBasedOnBackground(ele.nativeElement);
    })
  }

  openNotifyMeModal(content, productId) {
    // this.cartModal = true;

    if (localStorage.getItem("clientToken")) {
      // console.log("product id notify modal ", productId);
      this.productIdToListenToAvailability = productId;

      this.modalService
        .open(content, { ariaLabelledBy: "modal-basic-title" })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    } else {
      this.toastrService.warning(
        this.translateService.instant(
          "Please log in or register to get notified when this product is available"
        )
      );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  getDiscountPercentage(last_price, price) {
    var l_p = parseInt(last_price);
    var p = parseInt(price);
    var res = ((l_p - p) / l_p) * 100;
    return Math.round(res);
  }
  // Get Product Color
  // Color(variants) {
  //   const uniqColor = [];
  //   for (let i = 0; i < Object.keys(variants).length; i++) {
  //     if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
  //       uniqColor.push(variants[i].color)
  //     }
  //   }
  //   return uniqColor
  // }

  // Change Variants
  // ChangeVariants(color, product) {
  //   product.variants.map((item) => {
  //     if (item.color === color) {
  //       product.images.map((img) => {
  //         if (img.image_id === item.image_id) {
  //           this.ImageSrc = img.src;
  //         }
  //       })
  //     }
  //   })
  // }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  async addToCart(product: any) {
    // console.log(product.store_amounts);
    // console.log("addToCart in app-product-box-one component", product);
    // console.log("addToCart in app-product-box-one component", product);

    // this.productService.addToCart(product);
    const toast = await this.productService.addToCart(product);
    if (toast) {
      if (localStorage.getItem("front-lang") == "en") {
        this.toastrService.success("Product has been added in cart.");
      } else {
        this.toastrService.success("تم إضافة المنتج الى السلة ");
      }
      product.quantity_in_cart += 1;
    }
    //this.Common.cartUpdated.next('added')
    //this.Common.changeCart("");
    // this.Common.getCart();

    // PIXEL CODE

    // (window as any).gtag("event", "add_To_CARRT", {
    //   value: product.price,
    //   currency: "EGP",
    //   content_name: product.name,
    //   content_ids: product.id,
    // });

    // CODE PIXEL TAG
    // fbq("track", "AddToCart", {
    //   value: product.price,
    //   currency: "EGP",
    //   content_name: product.name,
    //   content_ids: product.id,
    // });
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
    this.isFavourit = true;
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  // Stop All Videos And Play selected Video
  stopAllMedia(id) {
    const videos = document.querySelectorAll("video");
    const currentVideo = document.getElementById(
      `currentVideo${id}`
    ) as HTMLVideoElement;

    videos.forEach((ele: HTMLVideoElement) => {
      if (ele.getAttribute("id") !== currentVideo.getAttribute("id")) {
        ele.pause();
      }
    });
  }

  getNoteDetails(noteID: number): void {
    this.noteLoader = true;
    this.Common.noteOn = true;
    this.note_id = noteID;
    const bottomSheetRef = this.bottomSheet.open(this.TemplateBottomSheet, {
      hasBackdrop: false,
    });

    this.Common.getNoteDetails(noteID).subscribe((res) => {
      console.log(res);
      this.noteLoader = false;
      if (res.success) {
        setTimeout(() => {
          $("#note_description").html(res.data.description);
        }, 500);
      } else {
        $("#note_description").html("");
      }
    });

    bottomSheetRef.afterDismissed().subscribe(() => {
      this.Common.noteOn = false;
    });
  }

  closeTemplateSheetMenu() {
    this.Common.noteOn = false;
    this.bottomSheet.dismiss();
  }

  handleImageError(event: any) {
    event.target.src = "./assets/images/product/placeholder.jpg";
  }

  handleVideoError(event: any) {
    const videoElement = event.target as HTMLVideoElement;
    videoElement.style.display = "none";
  }


  isColorDark(color: string): boolean {
    let r: number = 0,
      g: number = 0,
      b: number = 0;

    if (color.match(/^#([0-9a-f]{3}){1,2}$/i)) {
      let hex = color.substring(1);
      if (hex.length === 3) {
        hex = hex
          .split("")
          .map((h) => h + h)
          .join("");
      }
      const num = parseInt(hex, 16);
      r = (num >> 16) & 255;
      g = (num >> 8) & 255;
      b = num & 255;
    } else if (color.match(/^rgb/i)) {
      const rgbValues = color.match(/\d+/g);
      if (rgbValues) {
        r = parseInt(rgbValues[0], 10);
        g = parseInt(rgbValues[1], 10);
        b = parseInt(rgbValues[2], 10);
      }
    } else {
      throw new Error("Invalid color format");
    }

    const brightness = 0.299 * r + 0.587 * g + 0.114 * b;

    return brightness < 128;
  }

  setFontColorBasedOnBackground(element: HTMLElement): void {
    const backgroundColor = getComputedStyle(element).backgroundColor;
    if (this.isColorDark(backgroundColor)) {
      element.style.color = "white";
    } else {
      element.style.color = "black";
    }
  }

  shippingModal(description:string): void {
    this.modalService
      .open(this.templateModalShipping, {
        size: "lg",
        ariaLabelledBy: "templateModalShipping-Modal",
        centered: true,
        windowClass: "theme-modal newsletterm NewsLetterModal",
        scrollable: true,
        keyboard: true,
      })
      .result.then(
        (result) => {
          `Result ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          this.Common.noteOn = false;
        }
      );
        $("#shipping_description").html(description);
  }
}
