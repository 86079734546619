import { ExtractedData } from "../../../../assets/interfaces/category-support";
import {
  Component,
  OnInit,
  ElementRef,
  Input,
  Renderer2,
  SimpleChanges,
} from "@angular/core";
import { MasterApiService } from "../../services/master-api.service";
import { CommonService } from "../../services/common.service";
import { ProductService } from "../../services/product.service";
import { ViewEncapsulation } from "@angular/core";
import * as confetti from "canvas-confetti";

@Component({
  selector: "app-firework",
  templateUrl: "./firework.component.html",
  styleUrls: ["./firework.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FireworkComponent implements OnInit {
  contanctInfo: any;
  @Input() productItems: ExtractedData[] = [];
  selectedTab = 0;

  constructor(
    private elRef: ElementRef,
    private MasterApiService: MasterApiService,
    public commonService: CommonService,
    public productService: ProductService,
    private renderer2: Renderer2
  ) {}
  ngOnInit(): void {
    this.getAllContanctInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("changes", this.productItems);
  }
  getAllContanctInfo() {
    this.MasterApiService.getCurrentDataContact().subscribe(
      (dataContact) => {
        if (!dataContact) {
          console.error("Data contact information is undefined or null");
          return;
        }
        this.contanctInfo = dataContact;
      },
      (error) => {
        console.error("Error fetching contact info", error);
      }
    );
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.surprise();
    }, 300);
  }
  surprise(): void {
    const canvas = this.renderer2.createElement("canvas");

    this.renderer2.appendChild(this.elRef.nativeElement, canvas);
    const parentElement = this.elRef.nativeElement;
    console.log(parentElement);

    if (parentElement) {
      const { width, height } = parentElement.getBoundingClientRect();
      canvas.width = width;
      canvas.height = height;
      console.log(width, height);
      const myConfetti = confetti.create(canvas, {
        resize: true,
      });

      myConfetti({
        particleCount: 600,
        spread: 70,
        origin: { y: 1.2 },
      });

      setTimeout(() => {
        this.renderer2.removeChild(parentElement, canvas);
      }, 2000);
    }
  }

  selectTab(index: number): void {
    this.selectedTab = index;
  }
}
