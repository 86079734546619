<div class="row" style="row-gap: 15px; padding: 0 5px; max-width: 100%;">
    <div class="col-12" *ngIf="products.invoice_id">
        <p>{{'Order ID'|translate}}: {{products.invoice_id}}</p>
    </div>
    <div class="col-12">
        <p>{{'order date'|translate}}: {{commonService.getCurrentDate()}}</p>
    </div>
    <div class="col-12" *ngIf="products.date">
        <p>{{'expected_delivery_date'|translate}}: <span class="success-order-header">{{products.date}}</span></p>
    </div>
    <div class="col-12">
        <div class="order-desktop">
            <div class="flex-wrap d-flex align-items-center " style="gap: 15px;"
                *ngIf="products.product_items.length <=4">
                <div class="d-flex">
                    <div class="product-list" *ngFor="let product of products.product_items">
                        <img *ngIf="product.image" [src]="product.image" alt="الصورة"
                            (error)="handleImageError($event)" />
                        <span>{{ product.quantity}}</span>
                    </div>
                </div>
                <div>
                    <p class="mb-2">{{'product_count'|translate}}: {{ getTotalQuantity() }}</p>
                    <p>{{'Total-final'|translate}}: {{ products.total_price}}
                        {{productService.Currency.name}}</p>
                </div>
            </div>
            <div class="container px-3" *ngIf="products.product_items.length > 4">
                <div class="product-slider">
                    <swiper [config]="relatedProductsSliderConfig" [navigation]="false"
                        [style.direction]="commonService._lang_code == 'ar' ? 'rtl' : 'ltr'" #relatedSwiper>
                        <ng-template class="swiper-product" style="width: fit-content;"
                            *ngFor="let product of products.product_items" swiperSlide>
                            <div class="product-list">
                                <img *ngIf="product.image" [src]="product.image" alt="الصورة"
                                    (error)="handleImageError($event)" />
                                <span>{{ product.quantity}}</span>
                            </div>
                        </ng-template>
                    </swiper>
                    <div class="swiper-button-next" (click)="relatedSwiper.swiperRef.slidePrev();"></div>
                    <div class="swiper-button-prev" (click)="relatedSwiper.swiperRef.slideNext();"></div>
                </div>
                <div class="mt-2">
                    <p class="mb-2">{{'product_count'|translate}}: {{ getTotalQuantity() }}</p>
                    <p>{{'Total-final'|translate}}: {{ products.total_price}}
                        {{productService.Currency.name}}</p>
                </div>
            </div>
        </div>
        <div class="order-mobile">
            <div class="flex-wrap d-flex align-items-center" style="row-gap: 10px;"
                *ngIf="products.product_items.length <=3">
                <div class="d-flex">
                    <div class="product-list" *ngFor="let product of products.product_items">
                        <img *ngIf="product.image" [src]="product.image" alt="الصورة"
                            (error)="handleImageError($event)" />
                        <span>{{ product.quantity}}</span>
                    </div>
                </div>
                <div>
                    <p class="mb-2">{{'product_count'|translate}}: {{ getTotalQuantity() }}</p>
                    <p class="">{{'Total-final'|translate}}: {{ products.total_price}}
                        {{productService.Currency.name}}</p>
                </div>
            </div>
            <div *ngIf="products.product_items.length >3">
                <div class="product-slider">
                    <swiper [config]="relatedProductsSliderConfig" [navigation]="false"
                        [style.direction]="commonService._lang_code == 'ar' ? 'rtl' : 'ltr'" #relatedSwiper>
                        <ng-template class="swiper-product" *ngFor="let product of products.product_items" swiperSlide>
                            <div class="product-list">
                                <img *ngIf="product.image" [src]="product.image" alt="الصورة"
                                    (error)="handleImageError($event)" />
                                <span>{{ product.quantity}}</span>
                            </div>
                        </ng-template>
                    </swiper>
                    <div class="swiper-button-next" (click)="relatedSwiper.swiperRef.slidePrev();"></div>
                    <div class="swiper-button-prev" (click)="relatedSwiper.swiperRef.slideNext();"></div>
                </div>
                <div class="mt-2">
                    <p class="mb-2">{{'product_count'|translate}}: {{ getTotalQuantity() }}</p>
                    <p>{{'Total-final'|translate}}: {{ products.total_price}}
                        {{productService.Currency.name}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12">
        <p class="text-center success-order" *ngIf="singleorder">{{'track_order-single'|translate}}</p>
        <p class="text-center success-order" *ngIf="!singleorder">{{'track_order'|translate}}</p>
    </div>
    <div class="text-center col-12">
        <a class="view--order" [routerLink]="['/pages/my-orders']"
            (click)="navigateTOOrder()">{{'view_orders'|translate}}</a>
    </div>

    <div class="text-center col-12" style="margin: 4px 0;"
        *ngIf="contanctInfo.app_url_android !== null || contanctInfo?.app_url_ios !== null">
        <p class="text-center">{{ 'exclusive_offers' | translate: { zahra: contanctInfo?.front_name } }}</p>
    </div>
    <div class="col-12" *ngIf="contanctInfo.app_url_android !== null || contanctInfo?.app_url_ios !== null">
        <ul class="gap-3 app-buttons d-flex justify-content-center align-items-center">
            <li>
                <a href="{{contanctInfo?.app_url_android}}" target="_blank">
                    <img class="w-100" src="assets/images/download-google-play.webp"
                        alt="Get Zahra EG App at Google Play Store">
                </a>
            </li>
            <li>
                <a href="{{contanctInfo?.app_url_ios}}" target="_blank">
                    <img class="w-100" src="assets/images/download-app-store.webp" alt="Get Zahra EG App at App Store">
                </a>
            </li>
        </ul>
    </div>
</div>