<div class="single-product-layout">
    <a [routerLink]="['/shop/product/', product.id , product.meta_url ? product.meta_url?.split(' ').join('-') : removeBrackets(product.name)?.split(' ').join('-') ]" *ngIf="product.images.length > 0">
        <img class="product-image" [src]="urlimage+product.images[0].name" (error)="handleImage($event.target)" [alt]="product?.name"
            *ngIf="product.images[0]['type'] !== 'video'">
        <img class="product-image" src="assets/images/video.png" [alt]="product?.product_name"
            *ngIf="product.images[0]['type'] === 'video'">
    </a>
    <a [routerLink]="['/shop/product/', product.id , product.meta_url ? product.meta_url?.split(' ').join('-') : removeBrackets(product.name)?.split(' ').join('-') ]" *ngIf="product.images.length == 0">
        <img class="product-image" src="assets/images/product/placeholder.jpg">
    </a>

    <div class="title-quantity-price-block">
        <div class="title">
            <a [routerLink]="['/shop/product/', product.id , product.meta_url ? product.meta_url?.split(' ').join('-') : removeBrackets(product.name)?.split(' ').join('-') ]">{{ product.name ? product.name : product.product_name | titlecase | truncate:70}}</a>

        </div>
        <div class="quantity-price-block">
            <div class="price-block">
                <span class="price">
                    {{ (product.price | discount:product) * productService?.Currency.price }}
                    {{productService?.Currency.name}}
                </span>
                <del *ngIf="product?.price > 0 && (product?.last_price > 0)" class="old-price">{{ product?.last_price }}
                    {{productService?.Currency.name}}</del>
            </div>


        </div>

    </div>

    <div class="options-block">
        <div class="delete-button" (click)="removeItem(product.id)">
            <i class="fa fa-trash" aria-hidden="true"></i>
        </div>
        <div class="add-to-cart-button" (click)="addToCart(product)">
            <i class="fa fa-cart-plus" aria-hidden="true"></i>
        </div>

    </div>
</div>