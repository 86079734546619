<!-- breadcrumb start -->
<div class="breadcrumb-section">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <nav aria-label="breadcrumb" class="theme-breadcrumb">
                    <ol class="breadcrumb" *ngIf="!formShapesMode">
                        <li class="breadcrumb-item"><a [routerLink]="'/'">{{'home' | translate}}</a></li>
                        <li class="breadcrumb-item" *ngIf="title == 'الدفع' || title == 'checkout'"><a
                                [routerLink]="'/shop/cart'">{{'cart' | translate}}</a></li>
                        <li class="breadcrumb-item">{{title | translate}}</li>
                    </ol>
                    <ol class="breadcrumb" *ngIf="formShapesMode">
                        <li class="breadcrumb-item"><a [routerLink]="'/'">{{'home' | translate}}</a></li>

                        <li class="breadcrumb-item active" aria-current="page" *ngFor="let label of FormShapesLabels">
                            {{label.
                            label | translate
                            }}</li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</div>
<!-- breadcrumb End -->


<div class="page-title" [class.border-0]="!border" *ngIf="!formShapesMode">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h2 *ngIf="searchBreadcrumb==''" [class.remove-after]="!border">{{ title }}</h2>
                <h2 *ngIf="searchBreadcrumb" [class.remove-after]="!border">{{ searchBreadcrumb }}</h2>
            </div>
        </div>
    </div>
</div>
