<hr *ngIf="directRequests.length >0">

<div class="direct-request"
    *ngIf="directRequests.length>0 &&product?.active != 2 && !((product?.store_amounts == 0 && product?.price == 0) || (product?.store_amounts == 0 && product?.price > 0)) && !(product?.store_amounts> 0 && product?.price == 0)">
    <h5 class="text-center btn d-block w-100" style="background-color: var(--theme-default);"
        [style.color]="calculateTextColor('--theme-default')">{{'complete_request'|translate}}</h5>
    <div style="padding: 7px;">
        <div class="d-flex align-items-center" style="gap: 30px;">
            <span class="pieces">{{'number_of_pieces'|translate}}</span>

            <div class="qty-box">
                <div class="input-group">
                    <span class="input-group-prepend">
                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="increment()">
                            <i class="ti-plus"></i>
                        </button>
                    </span>
                    <input type="text" name="quantity" class="form-control input-number" [value]="new_counter"
                        disabled />
                    <span class="input-group-prepend">
                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="decrement()">
                            <i class="ti-minus"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
        <form [formGroup]="form_direct_request">
            <div class="text-start" *ngFor="let request of directRequests">
                <div class="flex-wrap gap-3 mb-1 d-flex align-items-center">
                    <label class="m-0" [for]="direct_labels[request.type]" style="white-space: nowrap;"
                        *ngIf="city_id != '' || request.type != 'district_id'">{{ direct_labels[request.type] }}</label>

                    <div *ngIf="form_direct_request.get(request.type)?.invalid && 
                           form_direct_request.get(request.type)?.touched && 
                           request.type != 'district_id' && 
                           request.type != 'note' && 
                           !form_direct_request.get(request.type)?.errors?.pattern && 
                           !(request.type === 'city_id' && city_id) && 
                           !(request.type === 'district_id' && district_id)" class="text-danger">
                        <span class="error-message" *ngIf="isArabicLang">
                            {{ 'lable-message' | translate }} {{ direct_labels[request.type] }}!
                        </span>
                        <span class="error-message" *ngIf="!isArabicLang">
                            {{ direct_labels[request.type] }} {{ 'lable-message' | translate }}!
                        </span>
                    </div>

                    <div *ngIf="form_direct_request.get(request.type)?.invalid && 
                           form_direct_request.get(request.type)?.touched && 
                           request.type == 'district_id' && 
                           city_id != '' && 
                           request.type != 'note' && 
                           !form_direct_request.get(request.type)?.errors?.pattern && 
                           !district_id" class="text-danger">
                        <span class="error-message" *ngIf="isArabicLang">
                            {{ 'lable-message' | translate }} {{ direct_labels[request.type] }}!
                        </span>
                        <span class="error-message" *ngIf="!isArabicLang">
                            {{ direct_labels[request.type] }} {{ 'lable-message' | translate }}!
                        </span>
                    </div>

                    <div class="text-danger"
                        *ngIf="form_direct_request.get(request.type)?.errors?.pattern && (request.type == 'mobile' || request.type == 'mobile_1')">
                        <span class="error-message" [translate]="'mobile_number_error'"
                            [translateParams]="{ mobileLength: mobileLength }"></span>
                    </div>
                </div>

                <input #fullnameInput type="text" [class.input-rtl]="isArabicLang" class="mb-2 form-control"
                    *ngIf="request.type == 'user_name'" [formControlName]="request.type"
                    [id]="direct_labels[request.type]" [placeholder]="request.label"
                    [name]="direct_labels[request.type]" autocomplete="off"
                    [ngClass]="{'is-invalid': form_direct_request.get(request.type)?.invalid && form_direct_request.get(request.type)?.touched}">

                <input type="text" class="mb-2 form-control" [class.input-rtl]="isArabicLang"
                    *ngIf="request.type != 'address' && request.type != 'city_id' && request.type != 'district_id' && request.type != 'user_name'"
                    [formControlName]="request.type" [id]="direct_labels[request.type]" [placeholder]="request.label"
                    [name]="direct_labels[request.type]" autocomplete="off"
                    [ngClass]="{'is-invalid': (form_direct_request.get(request.type)?.invalid && form_direct_request.get(request.type)?.touched) || 
                                      (form_direct_request.get(request.type)?.errors?.pattern && form_direct_request.get(request.type)?.touched)}">

                <textarea class="mb-2 form-control" [class.input-rtl]="isArabicLang" *ngIf="request.type == 'address'"
                    [id]="direct_labels[request.type]" [formControlName]="request.type" [placeholder]="request.label"
                    [name]="direct_labels[request.type]" rows="3" autocomplete="off"
                    [ngClass]="{'is-invalid': form_direct_request.get(request.type)?.invalid && form_direct_request.get(request.type)?.touched}"></textarea>
                <!-- 
                <div class="mb-2" *ngIf="country_id != '' && request.type == 'city_id'">
                    <ngx-select-dropdown [class.select-rtl]="isArabicLang" [config]="config" [options]="cityNames"
                        [formControlName]="request.type" name="city_id" (change)="onChangecity_id($event)">
                    </ngx-select-dropdown>
                </div>
                <div class="mb-2" *ngIf="city_id != '' && request.type == 'district_id' ">
                    <ngx-select-dropdown [class.select-rtl]="isArabicLang" [config]="config" [options]="districtNames"
                        [formControlName]="request.type" name="district_id" (change)="onChangeDistrict_id($event)">
                    </ngx-select-dropdown>
                </div> -->
                <div class="mb-2" [class.select-rtl]="isArabicLang"
                    *ngIf="country_id != '' && request.type == 'city_id'">
                    <ng-select [clearable]="false" [items]="cities" bindLabel="pla_name" bindValue="id"
                        [formControlName]="request.type" (change)="onChangecity_id($event)"
                        [placeholder]="request.label" (clear)="onClearCity()" [clearOnBackspace]="false"
                        [notFoundText]="'noItemsFoundCity'|translate">
                    </ng-select>
                </div>
                <div class="mb-2" [class.select-rtl]="isArabicLang"
                    *ngIf="city_id != '' && request.type == 'district_id' ">
                    <ng-select [clearable]="false" [items]="districts" bindLabel="pla_name" bindValue="id"
                        [formControlName]="request.type" (change)="onChangeDistrict_id($event)"
                        [placeholder]="request.label" [notFoundText]="'noItemsFoundDistrict'|translate"
                        (clear)="onClearDistrict()" [clearOnBackspace]="false">
                    </ng-select>
                </div>
            </div>
        </form>


        <div class="coupons-checker-layout">
            <div class="coupons-upper-text-block">
                <span class="coupon-question">
                    {{'got a coupon' | translate}}
                </span>
                <span class="coupon-showall" *ngIf="!showCouponsList" (click)="showCouponsList = true">
                    {{'show coupons' | translate}}
                </span>

                <i *ngIf="showCouponsList" (click)="showCouponsList = false" style="cursor: pointer" class="fa fa-times"
                    aria-hidden="true">
                </i>

            </div>
            <div class="coupons-list-all" *ngIf="showCouponsList">

                <span class="error-text" *ngIf="activeCouponesList.length == 0">
                    {{'no coupones found' | translate}}
                </span>


                <div class="coupone-block" *ngFor="let coupone of activeCouponesList; let index = index">
                    <div class="start-side">

                        <div class="coupon-title">
                            {{coupone.title}}
                        </div>
                        <div class="code-box">
                            {{coupone.code}}
                        </div>

                    </div>
                    <div class="mid-side" *ngIf="getCouponeAccordionStatus(index)">

                        <span class="sub-title" [innerHTML]="coupone.description">

                        </span>

                    </div>
                    <div class="end-side">

                        <div class="more-details" (click)="openCouponeDescription(index)">
                            <span *ngIf="!getCouponeAccordionStatus(index)">
                                {{'more details' | translate}}
                            </span>
                            <span *ngIf="getCouponeAccordionStatus(index)">
                                {{'hide details' | translate}}
                            </span>
                        </div>
                        <div class="confirm-coupon" (click)="confirmCouponToInput(coupone.code,product_id)">
                            {{'click to confirm' | translate}}</div>

                    </div>
                </div>
            </div>



            <div class="coupons-checker-block">
                <input type="text" [ngClass]="isCouponAdded ? 'coupon-added' : ''"
                    placeholder="{{'enter code here' | translate}}" [readonly]="isCouponAdded"
                    [(ngModel)]="codeValueCoupon" id="fname" name="fname">
                <div class="check-coupon" (click)="addCoupon(codeValueCoupon,product_id)" *ngIf="!isCouponAdded">
                    {{'activate' | translate}}
                </div>
                <div class="check-coupon" (click)="deleteCoupon(product_id)" *ngIf="isCouponAdded">
                    <i class="ti-trash"></i>
                </div>

            </div>

        </div>
        <div class="mt-3 form-group col-md-12 col-sm-12 col-xs-12 shipping-container"
            *ngIf="shipping_methods?.length > 0">
            <div class="field-label note-label d-flex justify-content-start align-items-center">
                <span class="gap-3 d-flex align-items-center">
                    <mat-icon>
                        directions_car
                    </mat-icon>
                    <span style="white-space: nowrap;">
                        {{'shipping company' | translate}}
                    </span>
                </span>
            </div>
            <mat-radio-group aria-labelledby="select an option" class="example-radio-group d-flex flex-column">
                <div class="p-1 mt-2" style="background-color: #fff;border-radius: 5px;border: 1px solid #dddddd;"
                    *ngFor="let company of shipping_methods let first=first let index=index"
                    (click)="selectComapny(radioContainer , btnCompany , company)" #radioContainer>
                    <div class="radio-container">
                        <mat-radio-button
                            (change)="onChangeCompany($event.value , company  , radioContainer , btnCompany)"
                            class="example-radio-button" [value]="company.shipment_id" #btnCompany>
                            <span style="margin-inline-end: 10px;">
                                {{company.shipment_company}}
                            </span>
                            <span *ngIf="company.delivery_price">
                                -
                            </span>
                            <span class="mx-2" *ngIf="company.delivery_price">
                                {{'the cost'|translate}}:
                                {{company.delivery_price +' '+ currency?.currency}}
                            </span>
                        </mat-radio-button>
                        <hr style="margin-top: 0; margin-bottom: 7px;">
                        <div style="margin: 0px 10px; text-align: start;">
                            <span>
                                {{company.message}}
                            </span>
                        </div>
                        <!-- Timer Section -->
                        <div
                            *ngIf="company.hour_shipped_orders && (company.delivery_time_from !='0' || company.delivery_time_to !='0') && calculateTimer(company.hour_shipped_orders)">
                            <p style="color:var(--theme-default);margin: 10px;">
                                {{'if you order that before' | translate}}
                                {{calculateTimer(company.hour_shipped_orders)?.hours }}
                                {{'hours' | translate}}
                                {{calculateTimer(company.hour_shipped_orders)?.minutes }}
                                {{'minutes' | translate}}
                                {{calculateTimer(company.hour_shipped_orders)?.seconds }}
                                {{'seconds' | translate}}
                            </p>
                        </div>

                    </div>
                </div>
            </mat-radio-group>

        </div>
        <div class="mt-3 form-group col-md-12 col-sm-12 col-xs-12 shipping-container"
            *ngIf="contactInfoData.payment_method_home=='1'||contactInfoData.payment_method_bank=='1'||contactInfoData.payment_method_card=='1'">
            <div class="field-label note-label d-flex justify-content-start align-items-center">
                <span class="gap-3 d-flex align-items-center">
                    <mat-icon>payment</mat-icon>
                    <span style="white-space: nowrap;">{{ 'payment method' | translate }}</span>
                </span>
            </div>

            <mat-radio-group aria-labelledby="select an option" class="example-radio-group d-flex flex-column">
                <div class="p-1 mt-2" style="background-color: #fff;border-radius: 5px;border: 1px solid #dddddd;"
                    *ngFor="let payment of paymentInfo let index =index"
                    (click)="selectPayment(PaymentContainer,btnpayment, payment.key,payment.name, payment.price);  payment.key === 'payment_method_card' ? openPaymentModal() : null"
                    #PaymentContainer>
                    <div class="radio-container">
                        <mat-radio-button
                            (change)="onChangePayment(payment.key,payment.name,payment.price, PaymentContainer, btnpayment)"
                            class="example-radio-button w-100" #btnpayment [value]="''">
                            <div class="flex-wrap d-flex justify-content-between align-items-center">
                                <span>{{ payment.name }}</span>
                                <div class="gap-3 d-flex justify-content-between align-items-center"
                                    *ngIf="payment.key =='payment_method_card'">
                                    <div class="payment-logo">
                                        <img src="./assets/images/payment logo/mada.svg" alt="payment-logo">
                                    </div>
                                    <div class="payment-logo">
                                        <img src="./assets/images/payment logo/visa.png" alt="payment-logo">
                                    </div>
                                    <div class="payment-logo">
                                        <img src="./assets/images/payment logo/master.svg" alt="payment-logo">
                                    </div>
                                </div>
                            </div>
                        </mat-radio-button>
                        <hr style="margin-top: 0; margin-bottom: 7px;" *ngIf="payment.text != ''">
                        <div style="margin: 0px 10px;" class="d-flex">
                            <span *ngIf="payment.text != ''">{{
                                payment.text
                                }}</span>
                            <span *ngIf="+(payment.price) > 0" style="margin: 0px 6px;"> {{
                                payment.price }}
                                {{productService?.Currency.name}}</span>
                        </div>
                    </div>
                </div>


            </mat-radio-group>

        </div>

        <div class="mx-2 d-flex justify-content-between" *ngIf="total_shipping_price">
            <p style="color:var(--theme-default)">{{'shipping cost'|translate}}</p>
            <p style="color:var(--theme-default)">{{total_shipping_price}} {{productService?.Currency.name}}</p>
        </div>
        <div class="mx-2 d-flex justify-content-between">
            <p style="color:var(--theme-default)">{{'Total-final'|translate}}</p>
            <p style="color:var(--theme-default)">{{total_price +total_payment_price+total_shipping_price }}
                {{productService?.Currency.name}}</p>
        </div>

        <button [disabled]="requestOrderLoading" (click)="onSubmit()" class="btn d-block w-100"
            style="background-color:orange; border-radius: 7px; padding: 10px; color: #fff; display: flex; align-items: center; gap: 20px;">
            <span class="mx-2 loader-check" *ngIf="requestOrderLoading"></span>
            {{'buy_now'|translate}}</button>

    </div>

</div>

<ng-template class="theme-modal" #paymentlet let-modal>
    <div class="modal-content">
        <div class="modal-body modal7" style="background-image: unset;">
            <div class="p-0 container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="p-1 modal-bg modal-close-payment">
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click'); resetPayment()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <h3 class="mb-3 text-center text-danger w-100">{{total_price
                        +total_payment_price+total_shipping_price}}
                        {{productService?.Currency.name}}</h3>
                    <ng-container *ngIf="!paymentLoading">
                        <div class="col-12" *ngFor="let method of paymentMethods" (click)="ExecutePayment(method)">
                            <div class="gap-3 cursor-pointer d-flex">
                                <div class="mb-3">
                                    <img [src]="method.ImageUrl" width="40px" alt="payment image">
                                </div>
                                <span>{{Common.isRtl? method.PaymentMethodAr: method.PaymentMethodEn }}</span>
                            </div>
                        </div>
                    </ng-container>

                    <div class="payment-loading" *ngIf="paymentLoading">
                        <img src="assets/images/loader.gif">
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template #addDirectRequest let-modal>
    <div class="modal-content">
        <div class="modal-body modal7" style="background-image: unset;">
            <div class="p-0 container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="p-1 modal-bg modal-close-payment">
                            <button type="button" class="close" aria-label="Close"
                                (click)="modal.dismiss('Cross click');">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>

                    <div class="my-5 w-100" *ngIf="directRequestLoading">
                        <img class="mx-auto d-block" src="assets/images/loader.gif">
                        <p class="my-2 text-center">
                            {{'direct-request-message'|translate}}
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</ng-template>

<ng-template class="theme-modal" #successOrder let-modal>
    <div class="modal-content position-relative">
        <div class="close-modal-btn">
            <div class="p-1 m-auto modal-bg modal-close-payment d-block">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="modal-body modal7" style="background-image: unset;">
            <div class="p-0 container-fluid">
                <div class="row">

                    <div class="col-12">
                        <div class="overflow-hidden position-relative">
                            <app-firework [productItems]="extractedData"></app-firework>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>