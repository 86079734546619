import {
  animate,
  keyframes,
  query,
  stagger,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const speedDialFabAnimations = [
  trigger("fabToggler", [
    state(
      "inactive",
      style({
        transform: "rotate(0deg)",
      })
    ),
    state(
      "active",
      style({
        transform: "rotate(180deg)",
      })
    ),
    transition("* <=> *", animate("300ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
  ]),
  trigger("speedDialStagger", [
    transition(":enter", [
      query(":enter", style({ opacity: 0, transform: "translateY(50px)" }), {
        optional: true,
      }),
      query(
        ":enter",
        stagger("100ms", [
          animate(
            "300ms cubic-bezier(0.4, 0.0, 0.2, 1)",
            style({ opacity: 1, transform: "translateY(0)" })
          ),
        ]),
        { optional: true }
      ),
    ]),
    transition(":leave", [
      query(":leave", [
        animate(
          "300ms cubic-bezier(0.4, 0.0, 0.2, 1)",
          style({ opacity: 0, transform: "translateY(200px)" })
        ),
      ]),
    ]),
  ]),
];
