<div class="related-slider">
    <swiper [config]="relatedProductsSliderConfig" [navigation]="false" [style.direction]="commonService._lang_code == 'ar' ? 'rtl' : 'ltr'" #relatedSwiper>
        <ng-template class="related-products-slide1"  
            *ngFor="let product of productsList" swiperSlide>
            <app-product-box-one  [product]="product" [currency]="productService?.Currency"
                [thumbnail]="false" [cartModal]="true">
            </app-product-box-one>
        </ng-template>
    </swiper>
    <div class="swiper-button-next" (click)="relatedSwiper.swiperRef.slidePrev();"></div>
    <div class="swiper-button-prev"(click)="relatedSwiper.swiperRef.slideNext();"></div>
</div>