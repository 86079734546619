import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() title: string;
  @Input() searchBreadcrumb: string = '';
  @Input() breadcrumb: string;
  @Input() formShapesMode: boolean;
  @Input() query: string;
  @Input() border: boolean = true;

  FormShapesLabels: any;
  constructor(private productService: ProductService) { }
  ngOnInit(): void {
    console.log("sameh", this.title);
    if (this.formShapesMode) {
      this.productService
        .getBreadcrumbFormShape(this.query)
        .subscribe((data) => {
          this.FormShapesLabels = data.data;
        });
    }
    this.generateBreadcrumbSchema()
  }

  generateBreadcrumbSchema() {
    const breadcrumbList = [
      {
        "@type": "ListItem",
        "position": 1,
        "name": "Home",
        "item": window.location.origin
      }
    ];

    if (this.title === 'الدفع' || this.title === 'checkout') {
      breadcrumbList.push({
        "@type": "ListItem",
        "position": 2,
        "name": "Cart",
        "item": `${window.location.origin}/shop/cart`
      });
    }

    breadcrumbList.push({
      "@type": "ListItem",
      "position": breadcrumbList.length + 1,
      "name": this.title,
      "item": window.location.href
    });

    const schema = {
      "@context": "https://schema.org",
      "@type": "BreadcrumbList",
      "itemListElement": breadcrumbList
    };

    console.log("schema", schema);
  }

}

